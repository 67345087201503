/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { useTheme } from '@mui/material/styles';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import p1 from 'assests/Photos/Clubs/LitreryClub/Enactment/1.jpg';
import p2 from 'assests/Photos/Clubs/LitreryClub/Enactment/mythcharacters35.png';
// import Card from '@mui/material/Card';
// import CardContent from '@mui/material/CardContent';
// import Grid from '@mui/material/Grid';
// import TextField from '@mui/material/TextField';
// import Button from '@mui/material/Button';
// // import Divider from '@mui/material/Divider';
// import Avatar from '@mui/material/Avatar';
// import IconButton from '@mui/material/IconButton';
// import FacebookIcon from '@mui/icons-material/Facebook';
// import TwitterIcon from '@mui/icons-material/Twitter';
// import InstagramIcon from '@mui/icons-material/Instagram';

const Form = () => {
  const theme = useTheme();
 
  // const photos = [
  //   {
  //     src: 'https://assets.maccarianagency.com/backgrounds/img25.jpg',
  //     rows: 1,
  //     cols: 2,
  //   },
  //   {
  //     src: 'https://assets.maccarianagency.com/backgrounds/img22.jpg',
  //     rows: 1,
  //     cols: 1,
  //   },
  //   {
  //     src: 'https://assets.maccarianagency.com/backgrounds/img24.jpg',
  //     rows: 1,
  //     cols: 1,
  //   },
  //   {
  //     src: 'https://assets.maccarianagency.com/backgrounds/img21.jpg',
  //     rows: 1,
  //     cols: 2,
  //   },
  // ];

  return (
    <Box>
      <Box paddingX={{ xs: 0, sm: 4, md: 6 }}>
        {/* <Typography variant='h4' align='center' fontWeight={700}>
        <br/>Literary Club
        </Typography>
        <br /><br/> */}
        <Typography variant='h5' fontWeight={600}>
        Enactment of Mythological Characters
        </Typography>
        <Typography fontSize = "x-small">
          Class 3 to 5 - 20.07.2022
        </Typography>
        <br/>
        <Typography variant={'subtitle1'} align={'justify'}>
        An enactment of Mythological characters with Hindi dialogues was conducted by the Hindi Department for the Students of Classes 3-5 on Wednesday, 20 July 2022. Students enacted different roles of few Mythological Characters like Lord Rama, Durga Devi, Shiva, Lord Krishna, etc. Students could describe the character’s traits and abilities of the Mythological Characters. It was an individual activity. All the students participated with a lot of energy and enthusiasm and used props that gave realistic touch to the activity.
        <br/>
        This activity portrayed our rich cultural heritage.
        </Typography>
        <Box width={1} height={1} marginY={4}>
          <LazyLoadImage
            height={'100%'}
            width={'100%'}
            src={p1}
            alt="Remote working"
            effect="blur"
            style={{
              filter:
                theme.palette.mode === 'dark' ? 'brightness(0.7)' : 'none',
              objectFit: 'cover',
              borderRadius: 8,
              width: '100%',
              height: '100%',
              maxHeight: 100,
            }}
          />
          <LazyLoadImage
            height={'100%'}
            width={'100%'}
            src={p2}
            alt="Remote working"
            effect="blur"
            style={{
              filter:
                theme.palette.mode === 'dark' ? 'brightness(0.7)' : 'none',
              objectFit: 'cover',
              borderRadius: 8,
              width: '100%',
              height: '100%',
              maxHeight: 400,
            }}
          />
        </Box>
        
       
        
        <Box marginY={4}>
        </Box>
      </Box>
    </Box>
  );
};

export default Form;

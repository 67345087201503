/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { useTheme } from '@mui/material/styles';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import p1 from 'assests/Photos/Clubs/LitreryClub/StoryNarration/1.jpg';
import p2 from 'assests/Photos/Clubs/LitreryClub/StoryNarration/2.jpg';
import p3 from 'assests/Photos/Clubs/LitreryClub/StoryNarration/3.jpg';
import p4 from 'assests/Photos/Clubs/LitreryClub/StoryNarration/photo.png';
// import Card from '@mui/material/Card';
// import CardContent from '@mui/material/CardContent';
// import Grid from '@mui/material/Grid';
// import TextField from '@mui/material/TextField';
// import Button from '@mui/material/Button';
// // import Divider from '@mui/material/Divider';
// import Avatar from '@mui/material/Avatar';
// import IconButton from '@mui/material/IconButton';
// import FacebookIcon from '@mui/icons-material/Facebook';
// import TwitterIcon from '@mui/icons-material/Twitter';
// import InstagramIcon from '@mui/icons-material/Instagram';

const StoryNaration = () => {
  const theme = useTheme();
 
  // const photos = [
  //   {
  //     src: 'https://assets.maccarianagency.com/backgrounds/img25.jpg',
  //     rows: 1,
  //     cols: 2,
  //   },
  //   {
  //     src: 'https://assets.maccarianagency.com/backgrounds/img22.jpg',
  //     rows: 1,
  //     cols: 1,
  //   },
  //   {
  //     src: 'https://assets.maccarianagency.com/backgrounds/img24.jpg',
  //     rows: 1,
  //     cols: 1,
  //   },
  //   {
  //     src: 'https://assets.maccarianagency.com/backgrounds/img21.jpg',
  //     rows: 1,
  //     cols: 2,
  //   },
  // ];

  return (
    <Box>
      <Box paddingX={{ xs: 0, sm: 4, md: 6 }}>
        
        <br />
        <Typography variant='h5' fontWeight={600}>
        STORY NARRATION (Kannada)

        </Typography>
        <Typography fontSize = "x-small">
        Classes 3-5 <br/>
        7 October 2022

        </Typography>
        <br/>
        <Typography variant={'subtitle1'} align={'justify'}>
        The students of Classes 3 to 5 participated in a Story Narration Competition as part of their CCA that was held on Friday, 7 October 2022. The event had finalists from different sections narrating wonderful tales with utmost confidence and enthusiasm. The students used colourful and interesting props to enhance their performance. Their acting skills and voice modulation was very visible in each of their presentation, thus captivating the attention of the listeners who thoroughly enjoyed the activity. The competition gave the young performers a platform to exhibit their creativity and  story telling skill, thus enhancing their confidence in speaking the language and using the language in an interesting manner. 


        </Typography>
        <br/>
        <LazyLoadImage
            height={'50%'}
            width={'50%'}
            src={p4}
            alt="Remote working"
            effect="blur"
            style={{
              filter:
                theme.palette.mode === 'dark' ? 'brightness(0.7)' : 'none',
              objectFit: 'cover',
              borderRadius: 8,
              width: '100%',
              height: '100%',
              maxHeight: 300,
            }}
          />
        <LazyLoadImage
            height={'50%'}
            width={'50%'}
            src={p3}
            alt="Remote working"
            effect="blur"
            style={{
              filter:
                theme.palette.mode === 'dark' ? 'brightness(0.7)' : 'none',
              objectFit: 'cover',
              borderRadius: 8,
              width: '100%',
              height: '100%',
              maxHeight: 300,
            }}
          />
        <Typography align='center' fontWeight={600}>
        No story lives unless someone wants to listen. The stories we love the best do live in us forever.
        </Typography>
        <Box width={1} height={1} marginY={4}>
          <LazyLoadImage
            height={'50%'}
            width={'50%'}
            src={p1}
            alt="Remote working"
            effect="blur"
            style={{
              filter:
                theme.palette.mode === 'dark' ? 'brightness(0.7)' : 'none',
              objectFit: 'cover',
              borderRadius: 8,
              width: '100%',
              height: '100%',
              maxHeight: 300,
            }}
          />
          <LazyLoadImage
            height={'50%'}
            width={'50%'}
            src={p2}
            alt="Remote working"
            effect="blur"
            style={{
              filter:
                theme.palette.mode === 'dark' ? 'brightness(0.7)' : 'none',
              objectFit: 'cover',
              borderRadius: 8,
              width: '100%',
              height: '100%',
              maxHeight: 300,
            }}
          />
        </Box>
        
       
        
        <Box marginY={4}>
        </Box>
      </Box>
    </Box>
  );
};

export default StoryNaration;
